import { authorisationGuard } from '@shared/guards';
import { InlightsRoute } from '@shared/models/shell/InlightsRoute';

export const imageJobsRoutes: InlightsRoute[] = [
	{
		title: "Image Jobs",
		icon: 'work',
		showInMenu: true,
		data: {
			allowAnonymousAccess: false,
			requiredClaims: [{ name: "image-jobs", value: ['read'] }],
		},
		path: 'image-jobs', canActivate: [authorisationGuard], loadComponent: () => import('./pages/image-jobs-index/image-jobs-index.component').then(m => m.ImageJobsIndexComponent),
		children: [
			{
				title: 'New Image Job',
				path: 'create', loadComponent: () => import('./pages/image-jobs-index/image-job-create/image-job-create.component').then(m => m.ImageJobCreateComponent)
			},
			{
				title: 'Image Job Details',
				path: 'details/:id', loadComponent: () => import('./pages/image-jobs-index/image-job-details/image-job-details.component').then(m => m.ImageJobDetailsComponent),
				children: [
					{ title: "Create Test Run", path: 'tests/create', loadComponent: () => import('./pages/image-jobs-index/image-job-details/image-job-create-test-run/image-job-create-test-run.component').then(m => m.ImageJobCreateTestRunComponent) }
				]
			}
		]
	},
];
